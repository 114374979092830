body {
    background: rgb(248, 248, 253);
    background: linear-gradient(180deg, rgba(220, 233, 246, 1) 10%, rgba(248, 248, 253, 1) 30%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
